.infographic-funds-container {
  .infographic-funds {
    background: url('/images/svg/infographic-funds.svg') no-repeat;
    background-size: contain;

    .infographic-funds-number {
      float: right;
      @include flexbox();
      justify-content: center;
      align-items: center;
    }
  }

  .infographic-funds .infographic-funds-number p, .infographic-funds-text p {
    text-align: center;
    font-weight: 900;
  }

  .infographic-funds .infographic-funds-number p {
    color: $royal;
  }

  .infographic-funds-text p {
    color: $strong-blue;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-funds-container {
    .infographic-funds {
      height: 270px;
      background-size: 320px;
      background-position: center;

      .infographic-funds-number {
        width: 131px;
        height: 130px;
        margin: 103px 0 0 -41px;
        left: 50%;
        position: absolute;

        p {
            font-size: 72px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      margin-top: 0px;

      p {
        font-size: 24px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-funds-container {
    .infographic-funds {
      height: 280px;
      background-position: center;

      .infographic-funds-number {
        width: 26%;
        height: 50%;
        margin: 20% 32% 0 0;

        p {
            font-size: 72px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      margin-top: 20px;

      p {
        font-size: 32px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-funds-container {
    .infographic-funds {
      height: 280px;
      background-position: center;

      .infographic-funds-number {
        width: 134px;
        height: 134px;
        margin: 108px 252px 0 0;

        p {
            font-size: 72px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      p {
        font-size: 32px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-funds-container {
    .infographic-funds {
      background-position: center;
      height: 220px;

      .infographic-funds-number {
        width: 107px;
        height: 107px;
        margin: 30% 24% 0 0;

        p {
            font-size: 42px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      p {
        font-size: 24px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-funds-container {
    .infographic-funds {
      background-position: center;
      height: 250px;

      .infographic-funds-number {
        width: 122px;
        height: 122px;
        margin: 27% 26% 0 0;

        p {
            font-size: 56px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      p {
        font-size: 32px;
      }
    }
  }
}
