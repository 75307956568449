.video-wide {
  min-height: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  position: relative;

  &-container {
    width: 100%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &-title {
    color: white;
    font-size: 56px;
  }

  &-button {
    font-size: 24px;
    color: white !important;

    &:after {
      content: url("/images/svg/arrow-right-white.svg") !important;
      margin-top: 4px !important;
    }
  }

  &-background-container {
    height: 500px;
  }

  &-background {
    background-color: #3200FB;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .5;
  }

  &-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
