.quote-container {
  padding: 60px;
  margin: 60px 0;
  opacity: .9999;

  &.quote-background-gradient {
    @include horizontal-gradient(.25, .25);

    .quote p.quote-text {
      &:before, &:after {
        opacity: 1;
      }

      &:before {
        content: url('/images/svg/quote-left-white.svg');
      }

      &:after {
        content: url('/images/svg/quote-right-white.svg');
      }
    }
  }

  .quote {
    color: $strong-blue;
    padding: 40px 60px;

    p.quote-text {
      text-align: center;
      font-size: 30px;
      color: $strong-blue;
      font-weight: 500;

      &:before, &:after {
        position: absolute;
        z-index: -1;
        opacity: .3;
      }

      &:before {
        content: url('/images/svg/quote-left.svg');
        top: 0;
        left: -20px;
      }

      &:after {
        content: url('/images/svg/quote-right.svg');
        right: -20px;
      }
    }

    p.quote-name, p.quote-title {
      color: $royal;
      text-align: center;
    }

    p.quote-name {
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 40px;
    }

    p.quote-title {
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  &.quote-container-small {
    padding: 0;

    .quote {
      padding: 0;

      p.quote-text {
        font-size: 24px;

        &:before {
          content: url('/images/svg/quote-left-small.svg');
        }

        &:after {
          content: url('/images/svg/quote-right-small.svg');
        }
      }
    }
  }

  &.quote-container-medium .quote p.quote-text {
    font-size: 24px;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .quote-container {
    padding: 20px;
    margin: 20px 0;

    &.quote-background-gradient {
      .quote p.quote-text {
        &:before, &:after {
          transform: scale(.5);
        }
      }
    }

    .quote {
      padding: 20px 0px;

      p.quote-text {
        font-size: 20px;

        &:before, &:after {
          transform: scale(.5);
        }
      }
    }

    &.quote-container-small {
      padding: 40px 60px 0;

      .quote {
        p.quote-text {
          font-size: 20px;

          &:before {
            margin-top: -30px;
            left: -60px;
          }
        }
      }
    }

    &.quote-container-medium .quote p.quote-text {
      font-size: 20px;
    }
  }
}
