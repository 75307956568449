.highlights {
  &-container {
    position: relative;
  }
}

.highlights-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    flex-direction: row;
    margin-bottom: 200px;
  }

  &-img {
    flex-basis: 50%;
    padding-right: 40px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-content {
    flex-basis: 50%;
  }

  &-heading {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 32px;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin-top: 0;
    }
  }

  &-number {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      font-size: 32px;
      font-weight: bold;
      border-radius: 100%;
      color: $strong-blue;
      background-image: linear-gradient(to right, rgba($brand-green,0.25) 0%,rgba($easter-purple,0.5) 100%);
    }
  }

  &-titles {

  }

  &-date {
    text-align: left;
    margin: 0 0 16px;
  }

  &-title {
    text-align: left;
    margin: 0;
  }

  &-text {

  }

  &-url-container {

  }

  &--mirrored {
    flex-direction: column;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-direction: row-reverse;
    }

    .highlights-element {
      &-img {
        padding-left: 40px;
        padding-right: 0;
      }
    }
  }


  &-img, &-text, &-url-container {
    padding-left: 100px;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      padding-left: 0;
    }
  }
}
