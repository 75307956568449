.infographic-board-container {
  .infographic-board {
    background: url('/images/svg/infographic-board.svg') no-repeat;
    background-size: contain;
    height: 541px;

    .infographic-board-text-container {
      float: right;

      .infographic-board-number p {
        margin: 20px 0 0;
      }

      .infographic-board-number p, .infographic-board-text p {
        color: #fff;
        text-align: center;
        font-weight: 900;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-board-container {
    .infographic-board {
      background-size: 320px;
      background-position: center;
      height: 370px;

      .infographic-board-text-container {
        width: 149px;
        height: 194px;
        left: 50%;
        position: absolute;
        margin: 40px 0 0 5px;
      }

      .infographic-board-number p {
          font-size: 60px;
      }

      .infographic-board-text {
        margin-top: -10px;

        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-board-container {
    .infographic-board {
      height: 545px;
      background-position: center;

      .infographic-board-text-container {
        width: 42%;
        height: 54.5%;
        margin: 9% 7% 0 0;
      }

      .infographic-board-number p {
        font-size: 100px;
      }

      .infographic-board-text p {
        font-size: 32px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-board-container {
    .infographic-board {
      background-position: center;

      .infographic-board-text-container {
        width: 229px;
        height: 297px;
        margin: 48px 111px 0 0;
      }

      .infographic-board-number p {
        font-size: 100px;
      }

      .infographic-board-text p {
        font-size: 32px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-board-container {
    .infographic-board {
      height: 313px;
      background-position: initial;

      .infographic-board-text-container {
        width: 131px;
        height: 172px;
        margin: 27px 7px 0 0;
      }

      .infographic-board-number p {
        font-size: 56px;
        margin-top: 16px;
      }

      .infographic-board-text {
        margin-top: -4px;

        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-board-container {
    .infographic-board {
      height: 386px;

      .infographic-board-text-container {
        width: 162px;
        height: 212px;
        margin: 34px 8px 0 0;
      }

      .infographic-board-number p {
        font-size: 70px;
        margin-top: 16px;
      }

      .infographic-board-text p {
        font-size: 24px;
      }
    }
  }
}
