.infographic-workplaces-container {
  .infographic-workplaces {
    background: url('/images/svg/infographic-workplaces.svg') no-repeat;
    background-size: contain;

    .infographic-workplaces-text-container {
      float: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      .infographic-workplaces-number p, .infographic-workplaces-text p {
        color: $strong-blue;
        text-align: center;
        font-weight: 900;
      }

      .infographic-workplaces-text p {
        margin: 0;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-workplaces-container {
    margin-top: 0px;

    .infographic-workplaces {
      height: 420px;
      background-size: 320px;
      background-position: center;

      .infographic-workplaces-text-container {
        width: 295px;
        height: 180px;
        margin: 197px 0 0 -151px;
        position: absolute;
        left: 50%;

        .infographic-workplaces-number p {
          font-size: 42px;
          line-height: 24px;
        }
        .infographic-workplaces-text p {
          font-size: 24px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-workplaces-container {
    .infographic-workplaces {
      height: 340px;
      background-position: center;

      .infographic-workplaces-text-container {
        width: 277px;
        height: 169px;
        margin: 157px 0 0 -142px;
        position: absolute;
        left: 50%;

        .infographic-workplaces-number p {
          font-size: 64px;
          line-height: 48px;
        }
        .infographic-workplaces-text p {
          font-size: 30px;
          line-height: 30px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-workplaces-container {
    .infographic-workplaces {
      height: 450px;
      background-position: center;

      .infographic-workplaces-text-container {
        width: 367px;
        height: 220px;
        margin: 209px 166px 0 0;

        .infographic-workplaces-number p {
          font-size: 72px;
          line-height: 64px;
          margin-bottom: 0;
        }
        .infographic-workplaces-text p {
          font-size: 36px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-workplaces-container {
    margin-top: -21px;

    .infographic-workplaces {
      height: 280px;
      background-position: center;

      .infographic-workplaces-text-container {
        width: 237px;
        height: 144px;
        margin: 46% 8% 0 0;

        .infographic-workplaces-number p {
          font-size: 42px;
          line-height: 42px;
          margin: 0;
        }
        .infographic-workplaces-text p {
          font-size: 24px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-workplaces-container {
    margin-top: -21px;

    .infographic-workplaces {
      height: 340px;
      background-position: center;

      .infographic-workplaces-text-container {
        width: 286px;
        height: 168px;
        margin: 45% 9% 0 0;

        .infographic-workplaces-number p {
          font-size: 52px;
          line-height: 52px;
          margin: 0;
        }
        .infographic-workplaces-text p {
          font-size: 32px;
          line-height: 36px;
        }
      }
    }
  }
}
