#hero-block {
  overflow: hidden;
  position: relative;
  height: 1120px;

  #hero-world {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;

    &-background {
      #hero-world-clouds {
        background: url('/images/svg/clouds.svg') repeat-x;
        background-position: 0 0;
        background-repeat: repeat-x;
        background-size: auto 500px;
        position: absolute;
        top: 0;
        right: -2609px;
        bottom: 0;
        left: 0;
        height: 500px;
        -webkit-animation: moveClouds 90s linear infinite;
        animation: moveClouds 90s linear infinite;
      }

      #hero-world-mountains {
        background: url('/images/svg/mountains.svg') repeat-x;
        background-position: 0 0;
        background-repeat: repeat-x;
        background-size: auto 180px;
        position: absolute;
        // top: 0;
        right: -2602px;
        bottom: 0;
        left: 0;
        height: 180px;
        margin-bottom: 300px;
        -webkit-animation: moveMountains 120s linear infinite;
        animation: moveMountains 120s linear infinite;
      }

      #hero-world-ground {
        background: linear-gradient(to right, #36ffa8, #bf76ff);
        height: 310px;
        width: 100%;
        position: absolute;
        bottom: 0;
        margin: 0;
      }
    }

    &-foreground {
      #hero-world-pollution {
        .floating-objects {
          left: 50%;
          position: absolute;
          -webkit-animation: floatingObjects 4s ease-in-out infinite;
          animation: floatingObjects 4s ease-in-out infinite;
        }
        .floating-objects-left {
          background-image: url('/images/svg/floating-objects-left.svg');
          width: 352px;
          height: 469px;
          margin-left: -640px;
          top: 170px;
        }

        .floating-objects-right {
          animation-delay: -1s;
          -webkit-animation-delay: -1s;
          background-image: url('/images/svg/floating-objects-right.svg');
          width: 465px;
          height: 492px;
          margin-left: 180px;
          top: 170px
        }
      }
      #hero-world-people {
        background-image: url('/images/svg/people.svg');
        background-position: 0 0;
        background-repeat: repeat-x;
        background-size: auto 360px;
        position: absolute;
        // top: 0;
        right: -2560px;
        bottom: 0;
        left: 0;
        height: 360px;
        margin-top: 0;
        margin-bottom: 20px;
        -webkit-animation: movePeople 45s linear infinite;
        animation: movePeople 45s linear infinite;
      }
      #hero-world-title {
        height: 500px;
        width: 600px;
        position: absolute;
        align-items: center;
        flex-direction: row;
        @include flexbox();
        text-align: center;
        top: 50%;
        left: 50%;
        margin: -400px 0 0 -300px;

        h1 {
          color: $strong-blue;
          font-size: 120px;
          font-weight: 900;
          text-align: center;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
  #hero-block {
    height: 800px;

    #hero-world {
      &-background {
        #hero-world-clouds {
          background-size: auto 300px;
        }

        #hero-world-mountains {
          background-size: auto 160px;
          height: 160px;
        }

        #hero-world-ground {
          background: linear-gradient(to right, #36ffa8, #bf76ff);
          height: 300px;
        }
      }

      &-foreground {
        #hero-world-pollution {
          display: none;
        }
        #hero-world-people {
          background-size: auto 240px;
          height: 280px;
          margin-bottom: 0px;
        }
        #hero-world-title {
          height: 200px;
          width: 320px;
          margin: -200px 0 0 -160px;

          h1 {
            font-size: 42px;
            margin-left: 30px;
            margin-right: 30px;
          }
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  #hero-block {
    #hero-world {
      &-foreground {
        #hero-world-pollution {
          display: block;

          .floating-objects-left {
            background-image: url('/images/svg/floating-objects-mobile-left.svg');
            width: 110px;
            height: 268px;
            margin-left: -200px;
          }

          .floating-objects-right {
            background-image: url('/images/svg/floating-objects-mobile-right.svg');
            width: 62px;
            height: 269px;
            margin-left: 120px;
          }
        }

        #hero-world-title {
          height: 200px;
          width: 320px;
          margin: -200px 0 0 -160px;

          h1 {
            font-size: 56px;
          }
        }
      }
    }
  }
}
