.infographic-members-container {
  .infographic-members {
    background: url('/images/svg/infographic-heads.svg') no-repeat;
    background-size: contain;

    .infographic-members-text-container {
      float: right;

      .infographic-members-number p, .infographic-members-text p {
        color: $strong-blue;
        text-align: center;
        font-weight: 900;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-members-container {
    .infographic-members {
      height: 320px;
      background-size: 400px;
      background-position: center;

      .infographic-members-text-container {
        width: 245px;
        height: 120px;
        margin: 106px 0 0 -113px;
        position: absolute;
        left: 50%;

        .infographic-members-number p {
          font-size: 42px;
          line-height: 24px;
        }
        .infographic-members-text p {
          font-size: 24px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-members-container {
    .infographic-members {
      height: 340px;
      background-position: center;

      .infographic-members-text-container {
        width: 245px;
        height: 120px;
        margin: 96px 0 0 -113px;
        position: absolute;
        left: 50%;

        .infographic-members-number p {
          font-size: 64px;
          line-height: 48px;
        }
        .infographic-members-text p {
          font-size: 30px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-members-container {
    .infographic-members {
      height: 450px;
      background-position: center;

      .infographic-members-text-container {
        width: 314px;
        height: 159px;
        margin: 130px 180px 0 0;

        .infographic-members-number p {
          font-size: 72px;
          line-height: 64px;
          margin-bottom: 0;
        }
        .infographic-members-text p {
          font-size: 36px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-members-container {
    .infographic-members {
      background-position: center;
      height: 300px;

      .infographic-members-text-container {
        width: 178px;
        height: 116px;
        margin: 28% 19% 0 0;

        .infographic-members-number p {
          font-size: 48px;
          margin: 0;
        }
        .infographic-members-text p {
          margin-top: -20px;
          font-size: 28px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-members-container {
    .infographic-members {
      height: 360px;
      background-position: center;

      .infographic-members-text-container {
        width: 220px;
        height: 142px;
        margin: 28% 19% 0 0;

        .infographic-members-number p {
          font-size: 52px;
          margin: 0;
        }
        .infographic-members-text p {
          margin-top: -20px;
          font-size: 36px;
        }
      }
    }
  }
}
