.infographic-calendar-container {
  .infographic-calendar {
    background: url('/images/svg/infographic-calendar.svg') no-repeat;
    background-size: contain;

    .infographic-calendar-number {
      float: right;
      @include flexbox();
      justify-content: center;
      align-items: center;
    }
  }

  .infographic-calendar .infographic-calendar-number p, .infographic-calendar-text p {
    color: $strong-blue;
    text-align: center;
    font-weight: 900;
  }

  .infographic-calendar-text {
    margin-top: 10px;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-calendar-container {
    .infographic-calendar {
      height: 340px;
      background-size: 320px;
      background-position: center;

      .infographic-calendar-number {
        width: 138px;
        height: 160px;
        margin: 115px 0 0 12px;
        position: absolute;
        left: 50%;

        p {
            font-size: 64px;
            margin-top: 10px;
        }
      }
    }

    .infographic-calendar-text p {
      font-size: 36px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-calendar-container {
    .infographic-calendar {
      height: 340px;
      background-position: center;

      .infographic-calendar-number {
        width: 136px;
        height: 158px;
        margin: 113px 0 0 18px;
        position: absolute;
        left: 50%;

        p {
            font-size: 64px;
            margin-top: 10px;
        }
      }
    }

    .infographic-calendar-text p {
      font-size: 46px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-calendar-container {
    .infographic-calendar {
      height: 340px;
      background-position: center;

      .infographic-calendar-number {
        width: 136px;
        height: 158px;
        margin: 17% 28% 0 0;

        p {
            font-size: 64px;
            margin-top: 10px;
        }
      }
    }

    .infographic-calendar-text p {
      font-size: 46px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-calendar-container {
    .infographic-calendar {
      height: 260px;
      background-position: center;

      .infographic-calendar-number {
        width: 116px;
        height: 138px;
        margin: 30% 8% 0 0;

        p {
          font-size: 64px;
          margin-top: 10px;
        }
      }
    }

    .infographic-calendar-text p {
      font-size: 32px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-calendar-container {
    .infographic-calendar {
      height: 320px;
      background-position: center;

      .infographic-calendar-number {
        width: 142px;
        height: 166px;
        margin: 31% 9% 0 0;

        p {
            font-size: 72px;
            margin-top: 10px;
        }
      }
    }

    .infographic-calendar-text p {
      font-size: 38px;
    }
  }
}
