.video-container {
  margin: 40px 0 60px;

  h2 {
    margin-top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.video-container-left {
    h2, p {
      text-align: left;
    }
  }

  &.video-container-right {
    flex-direction: row-reverse;

    h2, p {
      text-align: right;
    }

    a {
      float: right;
    }
  }

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
    margin: 20px 0 60px;

    h2 {
      margin-top: 20px;
    }

    &.video-container-right {
      h2, p {
        text-align: left;
      }

      a {
        float: left;
      }
    }
  }
}
