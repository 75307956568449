body {
  font-family: CircularPro;

  h1, h2, h3, h4, h5, h6 {
    color: $strong-blue;
    text-align: center;
  }

  h1 {
    font-size: 64px;
    margin: 60px 0;
    font-weight: 900;
  }

  h2 {
    font-size: 30px;
    margin: 30px 0;
    font-weight: 700;
  }

  h3 {
    font-size: 20px;
    margin: 20px 0;
    color: $easter-purple;
  }

  .fou-item {
    margin: 40px 0;

    p {
      &:before {
        content: url('/images/svg/arrow-right-blue.svg');
        padding-right: 20px;
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .section-title {

  }

  .section-subtitle {
    font-size: 24px;
    color: $royal;
    text-align: center;
    @include flexbox();
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;

    > p {
      margin: 0;
    }

    &:before {
      content: url('/images/svg/infographic-sparkles-left.svg');
      padding: 0 20px 0 40px;
    }

    &:after {
      content: url('/images/svg/infographic-sparkles-right.svg');
      padding: 0 40px 0 20px;
    }
  }

  p {
    color: $royal;
    font-size: 20px;
  }

  .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .content-extra-spacing {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .infographic-container {
    @include horizontal-gradient(.1, .2);
    padding: 80px 0 60px;
  }

  #navigation-block__logo, #footer-block__logo, #footer-block__arrow {
    cursor: pointer;
  }

  .btn-container.btn-container-center {
    @include flexbox();
    justify-content: center;
  }

  .btn-container {
    .btn {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .btn {
    margin-top: 10px;
    padding-right: 35px;

    &:after {
      content: url('/images/svg/arrow-right-blue.svg');
      height: 20px;
      width: 12px;
      position: absolute;
      margin: 1px 0 0 10px;
    }
    &:hover:after, &:active:after, &:focus:after {
      content: url('/images/svg/arrow-right-white.svg');
    }

    &.btn-outline-white {
      border: 2px solid #fff;
      text-transform: uppercase;
      border-radius: 0;
      font-size: 16px !important;

      &:after {
        content: url('/images/svg/arrow-right-white.svg');
        margin-top: 1px;
      }
    }
  }

  #footer-block__nav .btn.btn-outline-white {
    padding: 8px 32px 8px 12px;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
  body {
    h1, h2, h3 {
      text-align: left;
    }

    h1 {
      font-size: 36px;
      margin: 30px 0 30px;
    }

    h2 {
      font-size: 24px;
      margin: 30px 0;
    }

    h3 {
      font-size: 18px;
      margin: 20px 0;
    }

    p {
      font-size: 18px;
    }

    .section-subtitle {
      font-size: 20px;
      margin-bottom: 20px;
      text-align: left;

      &:before, &:after {
        display: none;
        padding: 0;
      }
    }

    .text-center {
      text-align: left;
    }

    .btn {
      &:after {
        content: url('/images/svg/arrow-right-light-blue.svg');
      }
      &:hover:after, &:active:after, &:focus:after {
        content: url('/images/svg/arrow-right-light-blue.svg');
      }
      &:hover, &:active, &:focus {
        background-color: #fff;
      }

      &.btn-outline-primary {
        // border: none;
        // text-transform: none;
        // text-decoration: underline;
        // color: $strong-blue;
        // padding-left: 0;
        border-color: $periwinkle-blue;
        color: $periwinkle-blue;
        font-size: 16px;
      }
    }

    .btn-container.btn-container-center {
      justify-content: flex-start;
    }

    #timeline {
      .btn {
        margin-top: 0;
        padding-right: 0;
        font-size: 18px;

        &:after {
          content: '';
        }
        &:hover:after, &:active:after, &:focus:after {
          content: '';
        }

        &.btn-outline-primary {
          border: none;
          text-transform: none;
          text-decoration: underline;
          color: $strong-blue;
          padding-left: 0;
        }
      }
    }
  }
}

.modal {
  justify-content: center;
  align-items: center;

  &.in {
    display: flex !important;
  }
}

.modal-body {
  padding: 0;
}

.modal-backdrop.in {
  opacity: .8;
}

@media (min-width: 544px) {
  .modal-dialog {
    width: 600px !important;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    width: 900px !important;
  }
}
